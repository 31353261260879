import { toRgba } from "core/helpers"
import { merge } from "lodash"
const HOST = process.env.REACT_APP_API_HOST
const VERSION = process.env.REACT_APP_VERSION

function delay(response) {
  return new Promise((resolve) => {
    if (response.status === 401) {
      setTimeout(() => resolve(response), 500)
    } else {
      resolve(response)
    }
  })
}

const handleErrors = async (response) => {
  if (!response.ok) {
    return response
  }

  if (response.status === 200 && response.headers && response.headers.get("X-Frontend-Version") !== null) {
    // localStorage.setItem("APP_API_VERSION", response.headers.get("X-Api-Version"))
    localStorage.setItem("APP_FRONTEND_VERSION", response.headers.get("X-Frontend-Version"))
  }

  const resolvedResponse = await response.json()
  return { ...resolvedResponse, ok: true }
}

export default {
  login: function (payload) {
    return fetch(`${HOST}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(delay)
      .then(handleErrors)
      .catch((err) => {
        console.error("Login request failed")
        return { ok: false }
      })
  },
  logout: function (payload) {
    return new Promise((resolve) => {
      return fetch(`${HOST}/logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${payload.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: payload.refreshToken }),
      }).then(() => {
        return resolve()
      })
    })
  },
  retrieveLoginConfig: () => {
    return fetch(`${HOST}/login`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors)
      .catch((err) => {
        const errorMessage = "There was a network error retrieving login configuration"
        console.error(errorMessage)
        return { ok: false }
      })
  },
  retrieveTranslations: (lang) => {
    return new Promise(async (resolve, reject) => {
      let translation
      try {
        const response = await fetch(`./locales/${lang}.json?v=${VERSION}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })

        translation = await response.json()
      } catch (err) {
        translation = (await import("./requests/translations.json")).default
      }

      resolve(translation)
    })
  },
  retrieveThemeConfig: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const themes = { light: "light", dark: "dark" }

        const storageTheme = localStorage.getItem("currentTheme")
        const isDarkTheme =
          storageTheme && storageTheme === themes.dark
            ? true
            : storageTheme && storageTheme === themes.light
            ? false
            : window.matchMedia("(prefers-color-scheme: dark)").matches

        const colorsFileName = isDarkTheme ? "colors.dark.json" : "colors.light.json"

        const baseThemeJson = await fetch(`./theme/theme.json?v=${VERSION}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (resp) => {
            const baseTheme = (await import(`./requests/theme/theme.json`)).default
            if (resp.status === 404 || Object.fromEntries(resp.headers)["content-type"].indexOf("text/html") > -1) {
              return baseTheme
            }
            const overriddenTheme = await resp.json()
            return merge(baseTheme, overriddenTheme)
          })
          .then((parsedJson) => parsedJson)
          .catch(async (err) => {
            console.error(
              "An error occured when trying to parse theme/theme.json. Please verify that the file contains valid json."
            )
            return (await import(`./requests/theme/theme.json`)).default
          })

        const colorsJson = await fetch(`./theme/${colorsFileName}?v=${VERSION}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (resp) => {
            const baseColors = (await import(`./requests/theme/${colorsFileName}`)).default
            if (resp.status === 404 || Object.fromEntries(resp.headers)["content-type"].indexOf("text/html") > -1) {
              return baseColors
            }
            const overriddenColors = await resp.json()
            return merge(baseColors, overriddenColors)
          })
          .then((parsedJson) => {
            return parsedJson
          })
          .catch(async (err) => {
            console.error(
              `An error occured when trying to parse theme/${colorsFileName}. Please verify that the file contains valid json.`
            )
            return (await import(`./requests/theme/${colorsFileName}`)).default
          })

        // Merging colors schema into final theme object
        let theme = merge(colorsJson, baseThemeJson)

        /* Fetch main top right login image (splash/landscape) or fallback to default */
        let mainTopRightSplash
        const mainTopRightSplashName = isDarkTheme
          ? "main-top-right-splash-dark.svg"
          : "main-top-right-splash-light.svg"
        const mainTopRightSplashImageResponse = new XMLHttpRequest()

        if (theme.login.images.main_top_right_splash) {
          mainTopRightSplashImageResponse.open("HEAD", "images/" + theme.login.images.main_top_right_splash, false)
          mainTopRightSplashImageResponse.send()

          if (mainTopRightSplashImageResponse.status === 200) {
            mainTopRightSplash = mainTopRightSplashImageResponse.responseURL
          } else {
            alert("Failed to load Main Right Bottom Splash image from theme")
          }
        } else {
          mainTopRightSplash = (await import(`./requests/images/${mainTopRightSplashName}`)).default
        }

        theme.login.images.main_top_right_splash = mainTopRightSplash
        /*************************************************/

        /* Fetch left bottom login image (splash/landscape) or fallback to default */
        let leftBottomSplash
        const leftBottomSplashImageResponse = new XMLHttpRequest()

        if (theme.login.images.left_bottom_splash) {
          leftBottomSplashImageResponse.open("HEAD", "images/" + theme.login.images.left_bottom_splash, false)
          leftBottomSplashImageResponse.send()

          if (leftBottomSplashImageResponse.status === 200) {
            leftBottomSplash = leftBottomSplashImageResponse.responseURL
          } else {
            alert("Failed to load Left Bottom Splash image from theme")
          }
        } else {
          leftBottomSplash = (await import("./requests/images/left-bottom-splash.svg")).default
        }

        theme.login.images.left_bottom_splash = leftBottomSplash
        /*************************************************/

        /* Fetch Background Login left image (for mobile devices) or fallback to default */
        let mobileSplash
        const defaultMobileSplashBg = (await import("./requests/images/mobile-splash.svg")).default
        const mobileSplashImageResponse = new XMLHttpRequest()

        if (theme.login.images.mobile_splash) {
          mobileSplashImageResponse.open("HEAD", "images/" + theme.login.images.mobile_splash, false)
          mobileSplashImageResponse.send()

          if (mobileSplashImageResponse.status === 200) {
            mobileSplash = mobileSplashImageResponse.responseURL
          } else {
            alert("Failed to load Small Splash image from theme")
          }
        } else {
          mobileSplash = defaultMobileSplashBg
        }

        theme.login.images.mobile_splash = mobileSplash
        /*************************************************/

        /* Fetch login Logo or fallback to default (ArrigoLogo) */
        let loginLogo
        const defaultLoginLogo = (await import("./requests/images/login-logo.svg")).default
        const loginLogoImageResponse = new XMLHttpRequest()

        if (theme.login.images.logo) {
          loginLogoImageResponse.open("HEAD", "images/" + theme.login.images.logo, false)
          loginLogoImageResponse.send()

          if (loginLogoImageResponse.status === 200) {
            loginLogo = loginLogoImageResponse.responseURL
          } else {
            alert("Failed to load Login Logo image from theme")
          }
        } else {
          loginLogo = defaultLoginLogo
        }

        theme.login.images.logo = loginLogo
        /*************************************************/

        /* Fetch header logo image or fallback to default (ArrigoLogo) */
        let headerLogo
        const headerLogoFilename = isDarkTheme ? "header-logo-dark.svg" : "header-logo-light.svg"
        const defaultHeaderLogo = (await import(`./requests/images/${headerLogoFilename}`)).default

        if (theme.header.images.logo) {
          const logoImageResponse = new XMLHttpRequest()
          logoImageResponse.open("HEAD", "images/" + theme.header.images.logo, false)
          logoImageResponse.send()
          if (logoImageResponse.status === 200) {
            headerLogo = logoImageResponse.responseURL
          } else {
            alert("Failed to load Logo image from theme")
          }
        } else {
          headerLogo = defaultHeaderLogo
        }

        theme.header.images.logo = headerLogo
        /*************************************************/

        theme.helpers = {
          toRgba,
        }

        theme.shadows = {
          ...theme.shadows,
          default: () => {
            return `0 0 20px ${toRgba(theme.shadows.color, 0.18)}`
          },
          large: () => {
            return `0 4px 20px ${toRgba(theme.shadows.color, 0.18)}`
          },
          small: () => {
            return `0 2px 5px ${toRgba(theme.shadows.color, 0.18)}`
          },
        }

        resolve(theme)
      } catch (err) {
        console.log("Error on retrieveThemeConfig()", err)
        reject(err)
      }
    })
  },
  testConnection: function () {
    return fetch(`${HOST}/login`)
      .then((response) => response.json())
      .then((response) => {
        return { ok: response.serviceStatus !== "offline" }
      })
      .catch((err) => {
        console.error("Connection test failed...")
        return { ok: false }
      })
  },
}
